import React, { useEffect, useState } from 'react';
import GTVRModule  from './GTVRModule.js';

//  "proxy": "http://localhost:3000",

export const Widget3DTest = () => {

    let loadWASMIsDone = false;

    const loadWASM = async () => {

        console.log("useEffect called!");

        if (loadWASMIsDone === true)
            return;

        const options = {
            canvas: 'gvrcanvas',
            wasm_url: process.env.PUBLIC_URL + 'wasm/',
            wasm_name: 'webxr_app',
            //project_url: 'https://dpa19em7573ub.cloudfront.net',
            project_url: 'https://gtvr-demo-ir.s3.eu-west-1.amazonaws.com',
            project_name: 'tungstenz',
        }
        let mod = new GTVRModule(options);
        mod.run();

        loadWASMIsDone = true;

    }

    useEffect(() => {
        loadWASM();
    }, []);

    return (
        <div>
            <canvas id="gvrcanvas" style={{ "backgroundColor": "rgba(0, 0, 0, 1.0)", "zIndex": "1000", "position": "absolute", "top": "0px", "left": "0px" }} onContextMenu={(event) => event.preventDefault()} />
        </div>
    );
} 
